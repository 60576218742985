var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "faq-question-page" } },
    [
      _c("nav", { staticClass: "page-question-nav" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M15 18L9 12L15 6",
                stroke: "#009FBF",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "category-link",
            attrs: { "aria-label": _vm.linkLabel },
            on: { click: _vm.navigateToCategory },
          },
          [_vm._v("\n      " + _vm._s(_vm.linkLabel) + "\n    ")]
        ),
      ]),
      _vm.isLoading
        ? _c("ct-centered-spinner")
        : _c("div", { staticClass: "faq-page-container" }, [
            _c("header", { staticClass: "faq-question-header" }, [
              _c("h1", { staticClass: "page-question" }, [
                _vm._v("\n        " + _vm._s(_vm.page.question) + "\n      "),
              ]),
              _c("hr", { staticClass: "divider" }),
            ]),
            _c(
              "div",
              { staticClass: "time-subheader" },
              [
                _c("clock"),
                _c("p", [
                  _c("i", [_vm._v("Updated:")]),
                  _vm._v(" " + _vm._s(_vm.formattedPageDate) + "\n      "),
                ]),
              ],
              1
            ),
            _c("section", { staticClass: "faq-page-content" }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.sanitizedPageContent) },
              }),
              _vm.hasValidVideo
                ? _c("div", { staticClass: "faq-page-video" }, [
                    _c("br"),
                    _c("video", { attrs: { controls: "" } }, [
                      _c("source", {
                        attrs: { src: _vm.page.video_url, type: "video/mp4" },
                      }),
                    ]),
                    _c("br"),
                  ])
                : _vm._e(),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }