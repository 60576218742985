<template>
  <b-container id="faq-question-page">
    <nav class="page-question-nav">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15 18L9 12L15 6"
          stroke="#009FBF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <a
        class="category-link"
        :aria-label="linkLabel"
        @click="navigateToCategory"
      >
        {{ linkLabel }}
      </a>
    </nav>
    <ct-centered-spinner v-if="isLoading" />
    <div v-else class="faq-page-container">
      <header class="faq-question-header">
        <h1 class="page-question">
          {{ page.question }}
        </h1>
        <hr class="divider">
      </header>
      <div class="time-subheader">
        <clock />
        <p>
          <i>Updated:</i> {{ formattedPageDate }}
        </p>
      </div>
      <section class="faq-page-content">
        <div v-html="sanitizedPageContent" />
        <div v-if="hasValidVideo" class="faq-page-video">
          <br>
          <video controls>
            <source
              :src="page.video_url"
              type="video/mp4"
            >
          </video>
          <br>
        </div>
      </section>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { faqMixin } from '@/mixins/faqMixin'
import * as DOMPurify from 'dompurify'

export default {
  name: 'FaqPage',
  components: {
    CtCenteredSpinner: () => import ('@/components/shared/CtCenteredSpinner.vue'),
    Clock: () => import('@images/ui/clock.svg'),
  },
  mixins: [faqMixin],
  computed: {
    ...mapGetters('account', ['account']),
    categoryId() {
      return this.$route.params.categoryId
    },
    pageId() {
      return this.$route.params.pageId
    },
    isLoading() {
      return !this.page
    },
    page() {
      return this.faqInformation
        ?.flatMap(category => category.faq_pages || [])
        .find(page => page.id === this.pageId) || null
    },
    hasValidVideo() {
      return this.page?.video_url?.includes('drive.google.com')
    },
    sanitizedPageContent() {
      return this.page ? DOMPurify.sanitize(this.page.content) : ''
    },
    formattedPageDate() {
      const updatedAt = this.page?.updated_at
      if (!updatedAt) return 'Unknown'

      return new Date(updatedAt).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },
    categoryName() {
      return this.faqInformation?.find(cat => cat.id === this.categoryId)?.name || 'Unknown'
    },
    linkLabel() {
      return `Back to ${this.categoryName || 'Categories'} link`
    },
  },
  async mounted() {
    if (!this.faqInformation?.length) await this.fetchFaqInformation()
  },
  methods: {
    navigateToCategory() {
      this.logFaqCategoryNavigation(this.categoryId, {
        action: 'visit',
        type: 'link',
        categoryName: this.categoryName,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#faq-question-page {

  .page-question-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 4.875rem;

    .category-link {
      font-weight: 600;
      color: #009FBF;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        opacity: 0.8;
      }
    }
  }

  .faq-page-container {

    .faq-question-header {

      .page-question {
        margin-top: 4.0rem;
        color: #4E4E52;
        font-size: 1.75rem;
        font-weight: 700;
      }

      .divider {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

    .time-subheader {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1.5rem;

      p {
        margin: 0;
        color: #707070;
      }
    }

    .faq-page-content {
      max-width: 52.0rem;
      width: 95%;
      margin-bottom: 4.5rem;
      white-space: pre-wrap;
      font-size: 1.0rem;
      font-weight: 400;

      video {
        width: 100%;
        max-width: 36.5rem;
        border-radius: 0.375rem;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  #faq-question-page {
    .page-question-nav {
      margin-top: 1.0rem;
    }

    .faq-page-container {
      .faq-question-header {
        ::v-deep .page-question {
          margin-top: 1.0rem;
          font-size: 1.25rem !important;
        }
      }
    }
  }
}
</style>
